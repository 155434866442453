
/* scrollbar */
@import '~simplebar-react/dist/simplebar.min.css';

/* carousel */
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

/* lightbox */
@import '~yet-another-react-lightbox/styles.css';
@import '~yet-another-react-lightbox/plugins/captions.css';
@import '~yet-another-react-lightbox/plugins/thumbnails.css';

/* lazy image */
@import '~react-lazy-load-image-component/src/effects/blur.css';


html {
    scroll-behavior: smooth;
  }

  /* Positionierung: unten links, 40px Abstand */
.cky-box-bottom-left {
  bottom: 40px;
  left: 40px;

}

/* Container, in dem sich das komplette Banner befindet */
.cky-consent-container {
  position: fixed;
  width: 440px;
  box-sizing: border-box;
  z-index: 9999999;
  border-radius: 6px;
  animation: fadeInUp 0.3s ease-out;
  right: 10px;
  bottom: -10px!important;

}

/* Hintergrund und Rahmen des Cookie-Banners */
.cky-consent-bar {
  background: #ffffff;
  border: 1px solid #f4f4f4;
  padding: 20px 26px;
  box-shadow: 0 -1px 10px 0 #acabab4d;
  border-radius: 6px;
}

/* Haupttitel */
.cky-title {
  color: #212121;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  margin: 0 0 12px 0;
}

/* Gruppe für Beschreibungstext + Buttons */
.cky-notice-group {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: #212121;
}

/* Beschreibungsblock */
.cky-notice-des {
  color: #212121;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
}

/* Wrapper für die Buttons */
.cky-notice-btn-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 16px;
  gap: 10px;
}

/* Allgemeiner Button-Stil */
.cky-btn {
  cursor: pointer;
  font-size: 14px;
  padding: 10px 16px;
  border-radius: 4px;
  border: 1px solid transparent;
  transition: all 0.2s ease;
}

/* Anpassen/Ablehnen-Button (blau umrandet, transparent) */
.cky-btn-customize,
.cky-btn-reject {
  color: #1863dc;
  background-color: transparent;
  border: 1px solid #1863dc;
}

.cky-btn-customize:hover,
.cky-btn-reject:hover {
  color: #fff;
  background-color: #1863dc;
}

/* Akzeptieren-Button (blau) */
.cky-btn-accept {
  color: #ffffff;
  background-color: #1863dc;
  border: 1px solid #1863dc;
}

.cky-btn-accept:hover {
  background-color: #0c49a4; /* etwas dunkler */
  border-color: #0c49a4;
}

/* Einfache Fade-in-Animation von unten */
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 20px, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@media (max-width: 440px) {
  .cky-consent-container {
      width: 100%;
  }
}

@media (max-width: 440px) {
  .cky-box-bottom-left, .cky-box-bottom-right, .cky-box-top-left, .cky-box-top-right {
      width: 100%;
      max-width: 100%;
  }
}

